:root {
  --box-bg-color: #24232c;
  --font-color: #817d92;
  --almost-white-color: #e6e5ea;
  --neon-green-color: #a4ffaf;
  --box-width: 25vw;
  --box-black-color: #18171f;
  --too-weak-color: #f64a4a;
  --weak-color: #fb7c58;
  --medium-color: #f8cd65;
  --strong-color: #a4ffaf;
}

/* Main Div Style -> Start */

.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Main Div Style -> End */

/* Heading Style -> Start */

#heading {
  color: var(--font-color);
  font-size: 1em;
}

/* Heading Style -> End */

/* Box Wrapper Style -> Start */

.box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Box Wrapper Style -> End */

/* Password Box Style -> Start */

.password-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--box-bg-color);
  padding: 0em 2em;
  border-radius: 5px;
  width: var(--box-width);
}

#password {
  color: var(--almost-white-color);
  font-size: 1.2em;
  letter-spacing: 0.1em;
  max-width: 100%;
  overflow: scroll;
}

#password::-webkit-scrollbar {
  display: none;
}

#copy-button {
  cursor: pointer;
  margin-left: 20px;
}

/* Password Box Style -> End */

/* Tools Box Style -> Start */

.tools-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--box-bg-color);
  padding: 0em 2em;
  border-radius: 5px;
  width: var(--box-width);
  margin-top: 20px;
}

.tool-row {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

#character-length-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#character-length-label {
  color: var(--almost-white-color);
}

#character-length-value {
  color: var(--neon-green-color);
  font-size: 1.2em;
}

#character-length-slider {
  width: 100%;
  overflow: hidden;
  background-color: black;
  overflow: hidden;
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  min-height: 10px;
  -webkit-appearance: none;
  color: var(--neon-green-color);
  margin-top: -1px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  cursor: e-resize;
  background: var(--almost-white-color);
  box-shadow: -195px 0 0 190px var(--neon-green-color);
}

.radiogroup-wrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: var(--almost-white-color);
}

.main-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  user-select: none;
}

.password-checkbox {
  visibility: hidden;
}

.mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: black;
  margin-top: 2px;
}

.password-checkbox:active ~ .mark,
.password-checkbox:checked ~ .mark {
  background-color: var(--neon-green-color);
}

.mark::after {
  content: '';
  position: absolute;
  display: none;
}

.mark {
  color: black;
  user-select: none;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
}

.main-checkbox input:checked ~ .geekmark:after {
  display: block;
}

#strength-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 91.5%;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 2px;
  margin-top: 10px;
  background-color: var(--box-black-color);
}

#strength-heading {
  color: var(--font-color);
  font-size: 0.9em;
}

#strength-value {
  width: 140%;
  color: var(--almost-white-color);
}

#strength-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.strength-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 10px;
}

.strength-box {
  width: 8px;
  height: 30px;
  border: 2px solid var(--almost-white-color);
  margin-left: 5px;
}

.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  background-color: var(--neon-green-color);
  font-weight: 700;
  margin-bottom: 20px;
  border: none;
}

.custom-button img {
  margin-left: 20px;
}

.too-weak {
  background-color: var(--too-weak-color);
  border: 2px solid var(--too-weak-color);
}

.weak {
  background-color: var(--weak-color);
  border: 2px solid var(--weak-color);
}

.medium {
  background-color: var(--medium-color);
  border: 2px solid var(--medium-color);
}

.strong {
  background-color: var(--strong-color);
  border: 2px solid var(--strong-color);
}

/* Tools Box Style -> End */

@media screen and (max-width: 1100px) {
  .password-box {
    width: 50vw;
  }

  .tools-box {
    width: 50vw;
  }

  input[type='range']::-webkit-slider-thumb {
    box-shadow: -205px 0 0 200px var(--neon-green-color);
  }
}

@media screen and (max-width: 768px) {
  .password-box {
    width: 70vw;
  }

  .tools-box {
    width: 70vw;
  }

  input[type='range']::-webkit-slider-thumb {
    box-shadow: -205px 0 0 200px var(--neon-green-color);
  }
}
